export const thumbnailNewsEntry = {
  fields: [
    'date_posted'
  ],
  populate: {
    ...queryFragmentCollectionTypeDefault,
    cover_image: true,
    cluster_entries: {
      populate: {
        ...queryFragmentCollectionTypeDefault,
      }
    }
  }
}
